





// Core
import { Component, Prop, Vue } from 'vue-property-decorator'
import PerfectScrollbar from 'perfect-scrollbar'

@Component({
  name: 'ScrollList',
})
export default class ScrollListComponent extends Vue {
  @Prop(Function) private loadMore!: () => void

  private mounted() {
    const scroll = this.$refs.scroll as Element
    const ps = new PerfectScrollbar(scroll)

    scroll.addEventListener('ps-y-reach-end', () => this.loadMore())
  }
}
